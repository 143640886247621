import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightimageComponent } from './rightimage.component';
import { SubscribePopUpComponent } from '../subscribe-pop-up/subscribe-pop-up.component';

@NgModule({
  declarations: [RightimageComponent],
  imports: [CommonModule],
  exports: [RightimageComponent],
})
export class RightimageModule {}
